import {
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';

interface ImpersonateContextValue {
  isImpersonateMode: boolean;
  impersonateFullName: string | null;
  impersonateCompanyName: string | null
  enterImpersonateMode: (userId: string, userFullname?: string, userCompanyName?: string) => void;
  exitImpersonateMode: () => void;
}

interface ImpersonateProviderProps {
  children: React.ReactNode;
}

const ImpersonateContext = createContext<ImpersonateContextValue>({
  isImpersonateMode: false,
  impersonateFullName: null,
  impersonateCompanyName: null,
  enterImpersonateMode: () => {},
  exitImpersonateMode: () => {},
});

export const useImpersonateContext = () => useContext(ImpersonateContext);

export const ImpersonateProvider: React.FC<ImpersonateProviderProps> = ({
  children,
}) => {
  const [isImpersonateMode, setIsImpersonateMode] = useState<boolean>(
    localStorage.getItem('impersonateId') !== null,
  );
  const [impersonateFullName, setImpersonateFullName] = useState<string | null>(
    localStorage.getItem('impersonateFullName'),
  );
  const [impersonateCompanyName, setImpersonateCompanyName] = useState<string | null>(
    localStorage.getItem('impersonateCompanyName'),
  );

  const enterImpersonateMode = (
    userId: string,
    userFullName?: string,
    userCompanyName?: string,
  ) => {
    localStorage.setItem('impersonateId', userId);
    localStorage.setItem('impersonateFullName', userFullName || '');
    localStorage.setItem('impersonateCompanyName', userCompanyName || '');

    setIsImpersonateMode(true);
    setImpersonateFullName(userFullName || '');
    setImpersonateCompanyName(userCompanyName || '');

    window.location.href = '/';
  };

  const exitImpersonateMode = () => {
    localStorage.removeItem('impersonateId');
    localStorage.removeItem('impersonateFullName');
    localStorage.removeItem('impersonateCompanyName');

    setIsImpersonateMode(false);
    setImpersonateFullName(null);
    setImpersonateCompanyName(null);

    window.location.href = '/';
  };

  const contextValue = useMemo<ImpersonateContextValue>(
    () => ({
      isImpersonateMode,
      impersonateFullName,
      impersonateCompanyName,
      enterImpersonateMode,
      exitImpersonateMode,
    }),
    [isImpersonateMode, impersonateFullName, impersonateCompanyName],
  );

  return (
    <ImpersonateContext.Provider value={contextValue}>
      {children}
    </ImpersonateContext.Provider>
  );
};
