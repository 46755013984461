/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AmendmentChangeType,
  AmendmentCompareSourceType,
  AmendmentsUpdatePayload,
  ContractActions,
  ContractStatusType,
  EquipmentType,
  ImportUpdatePayload,
  LegChangesListParams,
  LegChangesLocationsListParams,
  LocationType,
  Modality,
  TerminusType,
  UnlocodeGroupType,
} from "./api";
import { ContentType, HttpClient, RequestParams } from "./httpClient";

export class Amendments<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Return a single amendment
   *
   * @tags Amendment
   * @name AmendmentsDetail
   * @summary Get an amendment
   * @request GET:/amendments/{amendmentUid}
   * @secure
   */
  amendmentsDetail = (amendmentUid: string, params: RequestParams = {}) =>
    this.request<
      {
        title: string;
        uid: string;
        hasValidationErrors: boolean;
        parentContractUid?: string;
        contractCode: string | null;
        company?: {
          uid: string;
          name: string;
          /** @format date-time */
          deactivatedAt?: Date;
          /** @format date-time */
          archivedAt?: Date;
        };
        /** @format contract-status-type-enum */
        status: ContractStatusType;
        note?: string;
        /** @format date-time */
        publishedAt?: Date;
        /** @format date-time */
        acceptedAt?: Date;
        /** @format date-time */
        activeAt: Date;
        /** @format date-time */
        expiredAt: Date;
        /** @format date-time */
        createdAt: Date;
        /** @format date-time */
        updatedAt: Date;
        links?: {
          /** @format contract-actions-enum */
          rel: ContractActions;
          uri?: string | null;
          disabled?: boolean;
        }[];
      },
      {
        /** Error message */
        message?: string;
      }
    >({
      path: `/amendments/${amendmentUid}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Update an amendment
   *
   * @tags Amendment
   * @name AmendmentsUpdate
   * @summary Update amendment
   * @request PUT:/amendments/{amendmentUid}
   * @secure
   */
  amendmentsUpdate = (amendmentUid: string, data: AmendmentsUpdatePayload, params: RequestParams = {}) =>
    this.request<
      {
        title: string;
        uid: string;
        hasValidationErrors: boolean;
        parentContractUid?: string;
        contractCode: string | null;
        company?: {
          uid: string;
          name: string;
          /** @format date-time */
          deactivatedAt?: Date;
          /** @format date-time */
          archivedAt?: Date;
        };
        /** @format contract-status-type-enum */
        status: ContractStatusType;
        note?: string;
        /** @format date-time */
        publishedAt?: Date;
        /** @format date-time */
        acceptedAt?: Date;
        /** @format date-time */
        activeAt: Date;
        /** @format date-time */
        expiredAt: Date;
        /** @format date-time */
        createdAt: Date;
        /** @format date-time */
        updatedAt: Date;
        links?: {
          /** @format contract-actions-enum */
          rel: ContractActions;
          uri?: string | null;
          disabled?: boolean;
        }[];
      },
      {
        /** Error message */
        message?: string;
      }
    >({
      path: `/amendments/${amendmentUid}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Delete an amendment
   *
   * @tags Amendment
   * @name AmendmentsDelete
   * @summary Delete amendment
   * @request DELETE:/amendments/{amendmentUid}
   * @secure
   */
  amendmentsDelete = (amendmentUid: string, params: RequestParams = {}) =>
    this.request<
      void,
      {
        /** Error message */
        message?: string;
      }
    >({
      path: `/amendments/${amendmentUid}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * @description Updates the status of a amendment to declined.
   *
   * @tags Amendment
   * @name DeclineUpdate
   * @summary Updates the status of a amendment to declined.
   * @request PUT:/amendments/{amendmentUid}/decline
   * @secure
   */
  declineUpdate = (amendmentUid: string, params: RequestParams = {}) =>
    this.request<
      void,
      {
        /** Error message */
        message?: string;
      }
    >({
      path: `/amendments/${amendmentUid}/decline`,
      method: "PUT",
      secure: true,
      ...params,
    });
  /**
   * @description Updates the status of an amendment to accepted.
   *
   * @tags Amendment
   * @name AcceptUpdate
   * @summary Accept an amendment
   * @request PUT:/amendments/{amendmentUid}/accept
   * @secure
   */
  acceptUpdate = (amendmentUid: string, params: RequestParams = {}) =>
    this.request<
      void,
      {
        /** Error message */
        message?: string;
      }
    >({
      path: `/amendments/${amendmentUid}/accept`,
      method: "PUT",
      secure: true,
      ...params,
    });
  /**
   * @description Imports amendment legs from file
   *
   * @tags Amendment
   * @name ImportUpdate
   * @summary Import amendment legs
   * @request PUT:/amendments/{amendmentUid}/import
   * @secure
   */
  importUpdate = (amendmentUid: string, data: ImportUpdatePayload, params: RequestParams = {}) =>
    this.request<
      void,
      {
        /** Error message */
        message?: string;
      }
    >({
      path: `/amendments/${amendmentUid}/import`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.FormData,
      ...params,
    });
  /**
   * @description Get status for amendment import background jobs
   *
   * @tags Amendment
   * @name ImportList
   * @summary Get status for amendment import
   * @request GET:/amendments/{amendmentUid}/import
   * @secure
   */
  importList = (amendmentUid: string, params: RequestParams = {}) =>
    this.request<
      {
        progress?: number;
        hasValidationErrors?: boolean;
      },
      {
        /** Error message */
        message?: string;
      }
    >({
      path: `/amendments/${amendmentUid}/import`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Get validation errors for amendment import background jobs
   *
   * @tags Amendment
   * @name ValidationErrorsList
   * @summary Get validation errors for amendment import
   * @request GET:/amendments/{amendmentUid}/validationErrors
   * @secure
   */
  validationErrorsList = (amendmentUid: string, params: RequestParams = {}) =>
    this.request<
      {
        validationErrors?: {
          errorRow?: number | null;
          errorDescription?: string;
        }[];
      },
      {
        /** Error message */
        message?: string;
      }
    >({
      path: `/amendments/${amendmentUid}/validationErrors`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Get amendment changes for leg
   *
   * @tags Amendment
   * @name LegChangesList
   * @summary Get amendment changes for leg
   * @request GET:/amendments/{amendmentUid}/legChanges
   * @secure
   */
  legChangesList = ({ amendmentUid, ...query }: LegChangesListParams, params: RequestParams = {}) =>
    this.request<
      {
        total: number;
        count: number;
        page: number;
        limit: number;
      } & {
        result?: (({
          originCountry: string;
          originPlace: string;
          destinationCountry: string;
          destinationPlace: string;
          /** @format equipment-type-enum */
          equipmentType: EquipmentType;
          /** @format modality-enum */
          modality: Modality;
          origin?: {
            unlocode: string;
            countryCode: string;
            placeCode: string;
            name: string;
            plainName: string;
            coordinates?: {
              long?: number;
              lat?: number;
            };
            subDivision: string;
            status: string;
            sourceUpdated: string;
            IATA: string;
            /** @format date-time */
            createdAt: Date;
            /** @format date-time */
            updatedAt: Date;
          } | null;
          destination?: {
            unlocode: string;
            countryCode: string;
            placeCode: string;
            name: string;
            plainName: string;
            coordinates?: {
              long?: number;
              lat?: number;
            };
            subDivision: string;
            status: string;
            sourceUpdated: string;
            IATA: string;
            /** @format date-time */
            createdAt: Date;
            /** @format date-time */
            updatedAt: Date;
          } | null;
        } & {
          templateLegId: string;
          /** @format date-time */
          createdAt: Date;
          /** @format date-time */
          updatedAt: Date;
        }) & {
          service?: string;
          /** @format date-time */
          activeAt: Date;
          /** @format date-time */
          expiredAt: Date;
          tradeLane?: {
            origin: string;
            destination: string;
          } | null;
          /** @format amendment-change-type-enum */
          changeType?: AmendmentChangeType;
          changedFields?: {
            fieldName: string;
            newValue: string;
            compareValue: string;
            compareSource?: {
              legUuid?: string | null;
              groupUuid?: string | null;
              contractUid: string;
              /** @format amendment-compare-source-type-enum */
              type?: AmendmentCompareSourceType;
            };
            rowId?: number | null;
          }[];
        })[];
      },
      {
        /** Error message */
        message?: string;
      }
    >({
      path: `/amendments/${amendmentUid}/legChanges`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Return a paginated list of locations for amendment leg changes
   *
   * @tags Amendment
   * @name LegChangesLocationsList
   * @summary List available amendment leg changes locations
   * @request GET:/amendments/{amendmentUid}/legChanges/locations
   * @secure
   */
  legChangesLocationsList = ({ amendmentUid, ...query }: LegChangesLocationsListParams, params: RequestParams = {}) =>
    this.request<
      {
        total: number;
        count: number;
        page: number;
        limit: number;
      } & {
        result?: {
          filterValue?: string;
          /** @format location-type-enum */
          type?: LocationType;
          unlocode?: {
            unlocode: string;
            countryCode: string;
            placeCode: string;
            name: string;
            plainName: string;
            coordinates?: {
              long?: number;
              lat?: number;
            };
            subDivision: string;
            status: string;
            sourceUpdated: string;
            IATA: string;
            /** @format date-time */
            createdAt: Date;
            /** @format date-time */
            updatedAt: Date;
          } | null;
          unlocodeGroup?: {
            id: string;
            name: string;
            /** @format unlocodegroup-type-enum */
            type: UnlocodeGroupType;
            description?: string;
          } | null;
          /** @format terminus-type-enum */
          terminus?: TerminusType;
        }[];
      },
      {
        /** Error message */
        message?: string;
      }
    >({
      path: `/amendments/${amendmentUid}/legChanges/locations`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Updates the status of an Amendment to published
   *
   * @tags Amendment
   * @name PublishUpdate
   * @summary Updates the status of an amendment to published
   * @request PUT:/amendments/{amendmentUid}/publish
   * @secure
   */
  publishUpdate = (amendmentUid: string, params: RequestParams = {}) =>
    this.request<
      void,
      {
        /** Error message */
        message?: string;
      }
    >({
      path: `/amendments/${amendmentUid}/publish`,
      method: "PUT",
      secure: true,
      ...params,
    });
  /**
   * @description Removes the published status from an Amendment
   *
   * @tags Amendment
   * @name UnpublishUpdate
   * @summary Removes the published status from an amendment
   * @request PUT:/amendments/{amendmentUid}/unpublish
   * @secure
   */
  unpublishUpdate = (amendmentUid: string, params: RequestParams = {}) =>
    this.request<
      void,
      {
        /** Error message */
        message?: string;
      }
    >({
      path: `/amendments/${amendmentUid}/unpublish`,
      method: "PUT",
      secure: true,
      ...params,
    });
}
