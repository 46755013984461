/* eslint-disable no-underscore-dangle */
import { InternalAxiosRequestConfig } from 'axios';
import { ApiConfig, HttpClient } from './httpClient';
import { Auth } from './Auth';

const { VITE_API_URL } = import.meta.env;

function authApi() {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  return intercept(new Auth({ baseURL: VITE_API_URL }));
}

function intercept<T extends HttpClient>(api: T): T {
  // TODO SET TOKEN ACCORDING TO NEEDS.
  api.instance.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    const headers = {
      ...config.headers,
    };
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
    const impersonate = localStorage.getItem('impersonateId');
    if (impersonate) {
      headers['Act-As'] = impersonate;
    }
    return { ...config, headers } as unknown as InternalAxiosRequestConfig;
  });

  api.instance.interceptors.response.use(undefined, async (error) => {
    if (error.response.data?.message === 'Token expired.') {
      const originalConfig = error.config;
      const refreshToken = localStorage.getItem('refreshToken');

      if (refreshToken !== null && !originalConfig._retry) {
        originalConfig._retry = true;
        const response = await authApi().refreshTokenCreate(
          { refreshToken },
        );
        if (response?.token) {
          localStorage.setItem('token', response.token);
          return api.instance(originalConfig);
        }
      }
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      window.location.reload();
      return Promise.reject(new Error('Could not refresh token.'));
    }

    if (error.response.data?.message === 'Not authenticated.') {
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      window.location.reload();
      return Promise.reject(new Error('Not authenticated.'));
    }

    if (error?.code === 'ERR_NETWORK') {
      return Promise.reject(Error('Can\'t connect to the server. Maybe your network is down?'));
    }
    return Promise.reject(error);
  });

  return api;
}

export const client = <T extends HttpClient>(
  Superclass: new (config: ApiConfig) => T,
): T => {
  const api = new Superclass({
    baseURL: VITE_API_URL,
  });
  return intercept(api);
};
