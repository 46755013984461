export const queryKeys = {
  ALIASES: 'aliases',
  VERIFY_TOKEN: 'verify-token',
  LOGIN: 'login',
  PRIVILEGES_LIST: 'privileges-list',
  EXCHANGE_RATES: 'exchange-rates',
  ENV_CONFIG: 'env-config',
  UNLOCODES: 'unlocodes',
  COMPANIES: 'companies',
  COMMODITIES: 'commodities',
  USERS: 'users',
  USER_DEFAULT_FIELDS: 'user-default-fields',
  TENDERS: 'tenders',
  TENDER_DEFAULT_FIELDS: 'tender-default-fields',
  CONTRACT_YEARS: 'contract-years',
  CONTRACT_CODES: 'contract-codes',
  CONTRACT_VALIDATION_ERRORS: 'contract-validation-errors',
  AMENDMENT_VALIDATION_ERRORS: 'amendment-validation-errors',
  TENDER_STATUSES: 'tender-statuses',
  TENDER_IMPORT_PROGRESS: 'tender-import-progress',
  AMENDMENT_IMPORT_PROGRESS: 'amendment-import-progress',
  CONTRACT_IMPORT_PROGRESS: 'contract-import-progress',
  TENDER_DETAILS: 'tender-details',
  TENDER_LEGS: 'tender-legs',
  TENDER_LEG_DETAILS: 'tender-leg-details',
  TENDER_LOCATION: 'tender-location',
  TEMPLATE_LEGS_LOCATION: 'template-legs-location',
  LEG_MODALITY_OPTIONS: 'leg-modality-options',
  LEG_EQUIPMENT_TYPE_OPTIONS: 'leg-equipment-type-options',
  CONTRACTS: 'contracts',
  CONTRACT_DETAILS: 'contract-details',
  CONTRACT_LEGS_LOCATION: 'contract-legs-location',
  CONTRACT_LEGS: 'contract-legs',
  CONTRACT_LEG_DETAILS: 'contract-leg-details',
  CONTRACT_CODES_LIST_SEARCH: 'contract-codes-list-search',
  AMENDMENT_LEG_CHANGES_LIST: 'amendment-leg-changes-list',
  UNLOCODE_GROUPS: 'unlocode-groups',
  SEARCH_INSTANCE: 'search-instance',
  SEARCH_RESULTS_LIST: 'search-results-list',
  SEARCH_RESULT_ITEM_DETAILS: 'search-result-item-details',
  SEARCH_LOCATION: 'search-location',
  NEWS: 'news', // the result of this is the list of news that we have
  NEWS_FIELDS: 'news-fields',
  NEWS_FIELDS_FILE_LIST: 'news-file-list',
  PORTALS: 'portals',
  TIERS: 'tiers',
  ALLOWED_CARRIERS: 'allowed-carriers',
  TIER_COSTS: 'tier-costs',
  USER_PREFERENCES_DISPLAY: 'user-preferences-display',
  NOTIFICATION_SETTINGS: 'notification-settings',
  USER_LOGS: 'user-logs',
  USER_LOGS_FILE_EXPORT: 'user-logs-file-export',
  UNLOCODES_IN_UNLOCODE_GROUP: 'unlocodes-in-unlocode-group',
  UPDATE_UNLOCODES_IN_UNLOCODE_GROUP: 'unlocodes-in-unlocode-group',
  SEARCH_CARRIERS: 'search-carriers',
};
